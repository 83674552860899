import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Footer link

//Import Images
import footerlogo from "../../assets/images/logo-dark.png";

class Footer extends Component {

  render() {
    return (
      <React.Fragment>
        {/* FOOTER START  */}
        <footer className="pt-5 pb-4 position-relative bg-light">
          <Container className="container">
            <Row>
              <Col lg={5}>
                <h4>Endereço</h4>
                <div className="footer-item text-muted">
                  <div className="footer-item-icon">
                    <i className="remixicon-building-2-fill"/>
                  </div>
                  <div className="footer-item-text">
                    <p className="mb-0">Av. Miguel Estéfano, 1086 - Saúde</p>
                    <p className="mb-0">São Paulo -  SP</p>
                    <p>04301-000</p>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <h4>Contatos</h4>
                <div className="footer-item text-muted mb-2">
                  <div className="footer-item-icon">
                    <i className="remixicon-phone-fill"/>
                  </div>
                  <div className="footer-item-text">
                    <p className="mb-0">+55 (11) 3571-1481</p>
                  </div>
                </div>
                <div className="footer-item text-muted">
                  <div className="footer-item-icon">
                    <i className="remixicon-mail-fill"/>
                  </div>
                  <div className="footer-item-text">
                    <p className="mb-4">contato@angoera.com.br</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="text-center">
                <img
                  src={footerlogo}
                  alt="footerLogo"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </footer>

        {/* FOOTER END  */}
      </React.Fragment>
    );
  }
}

export default Footer;
