import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
//Import Image
// import googleImg from "../../assets/images/google-img.png";
import bottomShap from "../../assets/images/home-4-bottom-shape.png";
// import img1 from "../../assets/images/features-img-3.png";


class Hero extends Component {
  render() {
    return (
      <React.Fragment>
        {/* HERO START */}
        <section className="hero-4-bg position-relative _bg-gradient circuit-image" id="home">
          <Container className="container">
            <Row className="align-items-center hero-4-content">
              <Col lg={5}>
                <Fade bottom>
                <h1 className="text-white hero-4-title font-weight-medium mb-4 line-height-1_4">
                  Produtos eletrônicos sob medida
                </h1>
                </Fade>
                <Fade bottom>
                <h4 className="text-white-50 f-20">
                Trazemos vantagem competitiva ao seu negócio, através do desenvolvimento de produtos tecnológicos e inovadores.
                </h4>
                </Fade>
                <Fade bottom>
                <Link to="/historia" className="btn btn-primary mt-4">
                  Saiba mais sobre a Angoera
                </Link>
                </Fade>
              </Col>
            </Row>
          </Container>
          <div className="container-fluid">
            <Row className="row">
              <div className="home-bottom-shape">
                <img
                  src={bottomShap}
                  alt="Shap"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Row>
          </div>
        </section>

        {/* HERO END  */}
      </React.Fragment>
    );
  }
}

export default Hero;
