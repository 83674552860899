import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
//Import Image
import heroBgImg from "../../assets/images/hero-bg-1.jpg";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        {/* HERO START */}
        <section
          className="hero-1-bg d-flex vh-100 mx-auto flex-column align-items-center justify-content-center position-relative"
          id="home"
          style={{ background: `url(${heroBgImg}) center center` }}
        >
          <div className="bg-overlay" />
          <div className="bg-hero-overlay" />

          <Container>
            <Row className="align-items-center">
              <Col lg="5">
                <div className="hero-content">
                  <h1 className="text-dark hero-1-title line-height-1_4 mb-4">
                    Nossa
                    <span className="text-navy-blue"> história</span>
                  </h1>
                  <p className="text-muted f-20">
                    10 Anos de Excelência contribuindo para o ecossistema tecnológico com produtos inovadores.
                  </p>
                </div>
                
              </Col>

            </Row>
          </Container>
        </section>

        {/* HERO END  */}
      </React.Fragment>
    );
  }
}

export default Section;
