import React, { Component } from "react";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import History from "./history";
import Footer from "../../components/Footer/footer";
import Partners from "./partners";
import Achievements from "./achievements";

class HistoryPage extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     pos: document.documentElement.scrollTop,
  //     imglight: false,
  //     navClass: "",
  //   };
  // }

  // componentDidMount() {
  //   window.addEventListener("scroll", this.scrollNavigation, true);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.scrollNavigation, true);
  // }

  // scrollNavigation = () => {
  //   var scrollup = document.documentElement.scrollTop;

  //   if (scrollup > this.state.pos) {
  //     this.setState({ navClass: "darkheader", imglight: false });
  //   } else if (window.innerWidth <= 768) {
  //     this.setState({ navClass: "darkheader", imglight: false });
  //   } else {
  //     this.setState({ navClass: "", imglight: false });
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}

        <NavbarPage isMain={false} />

        {/* import section */}
        <Section />

        <History />

        <Partners />

        <Achievements />

        <Footer />
      </React.Fragment>
    );
  }
}

export default HistoryPage;
