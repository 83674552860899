import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../../components/Common/SectionTitle";
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Concepção",
        icon: "icon-ideation",
        description: "Identificamos a real necessidade do cliente aliado a oportunidade de inovação. Também consideramos todas as restrições de projeto (custo, time-to-market, outras), além de outras questões na concepção do produto.",
        // eslint-disable-next-line no-multi-str
        description2: "Qual é a real necessidade do seu negócio?\n\
          Que oportunidade de inovação temos em mãos?\n\
          Quais são as restrições de projeto (custo, time-to-market, outras)?\n\
          Quais as tecnologias que podemos utilizar para agregar valor à solução?\n\
          Essas e outras questões são analisadas na etapa de concepção do produto.",
        link: "#",
      },
      {
        title: "Desenvolvimento",
        icon: "icon-development",
        description: "A partir das definições do produto damos início a sua realização. Com uma equipe capacitada e experiente, a Angoera realiza o projeto mecânico e eletrônico do produto, utilizando as melhores práticas de engenharia.",
        description2: "PoC, MVP, produto final, melhorias\nDesign\nProjeto mecânico\nProjeto de hardware\nProjeto de firmware\nPrototipagem\nProjeto de software\nAplicativo\nAplicação web",
        link: "#",
      },
      {
        title: "Fabricação",
        icon: "icon-production",
        description: "Conte com a Angoera no projeto de fabricação e na industrialização do seu produto, desde o lote piloto até a fabricação do produto em grande escala.",
        // eslint-disable-next-line no-multi-str
        description2: "Desenvolvimento de fornecedores\n\
          Gestão de compras e importações\n\
          Fabricação de lote piloto e pequenos lotes\n\
          Desenvolvimento de ferramentas e processos de fabricação\n\
          Projeto e fabricação de moldes\n\
          Fabricação em escala",
        link: "#",
      },
      {
        title: "Acompanhamento",
        icon: "icon-followup",
        description: "Realizamos o acompanhamento do produto em campo, identificando oportunidades de melhoria e dando suporte às equipes de assistência técnica e vendas.",
        // eslint-disable-next-line no-multi-str
        description2: "Certificações\n\
          Assistência técnica\n\
          Manuais e treinamentos de equipe\n\
          Melhoria contínua",
        link: "#",
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="service" className="section position-relative">
          <Container>
            <SectionTitle
              title="Da ideia a fabricação"
              description="A Angoera atuará em todas as etapas para você realizar seu produto eletrônico."
            />

            <Row className="align-items-center">
              <Col lg={12}>
                <ServiceBox services={this.state.services} />
              </Col>
              <div className="col-lg-8 offset-lg-1">
                <div className="row">
                </div>
              </div>
            </Row>
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default Services;
