import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Common/SectionTitle";

//Import Client images
import alexandre from "../../assets/images/partners/partner-alexandre.jpg";
import rodrigo from "../../assets/images/partners/partner-rodrigo.jpg";

class Partners extends Component {
  state = {
    clients: [
      {
        id: 1,
        img: alexandre,
        name: "Alexandre Rosa",
        technology: "CEO",
        linkedin: "https://www.linkedin.com/in/alexandre-rosa-b683b69/",
        description:
          "Engenheiro de Sistemas Eletrônicos – Poli – USP",
        description2: "MBA Gestão Empresarial – FIA"
        
      },
      {
        id: 2,
        img: rodrigo,
        name: "Rodrigo Ladeira",
        technology: "CTO",
        linkedin: "https://www.linkedin.com/in/rodrigo-tadeu-oliveira-ladeira-b6827513/",
        description:
          "Engenheiro de Sistemas Eletrônicos – Poli – USP",
        description2: "Especialista em desenvolvimento de hardware e software"
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* <!-- TESTIMONIAL START --> */}

        <section className="section position-relative" id="client">
          <Container>
            <SectionTitle
              title="SÓCIOS - FUNDADORES"
              description=""
            />

            <Row className="mt-4">
              
              {this.state.clients.map((clients, key) => (
                <Col lg={6} key={key}>
                  <a href={clients.linkedin} target="_blank" rel="noopener noreferrer">
                    <div className="item">
                      <div className="testi-content-2 m-3">
                        
                        <div className="testi-img-content text-center">
                          <img
                            src={clients.img}
                            alt="client1"
                            className="img-fluid mx-auto d-block rounded-circle"
                          />
                          <h6 className="text-navy-blue mt-3 mb-0 f-20">{clients.name}</h6>
                          <p className="text-muted f-14">{clients.technology}</p>
                        </div>
                        <div className="texti-2-box rounded p-4">
                          <div className="float-left mr-3">
                            <i className="testi-2-icon h3" />
                          </div>
                          <div className="position-relative overflow-hidden">
                            <p className="f-15 mb-0 text-center text-muted">
                              {clients.description}
                            </p>
                            <p className="f-15 mb-0 text-center text-muted">
                              {clients.description2}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        {/* TESTIMONIAL END */}
      </React.Fragment>
    );
  }
}

export default Partners;
