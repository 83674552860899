import React, { Component } from "react";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Hero from "./hero";
import Technologies from "./technologies";
import Services from "./services";
import Taas from "./taas";

import Clients from "./clients";
import ContactUs from "./contactus";
import Footer from "../../components/Footer/footer";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
      isStickyNav: true,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);

    this.scrollNavigation();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > this.state.pos) {
      this.setState({ navClass: "darkheader", imglight: false });
      this.setState({ isStickyNav: false });
    } else if (window.innerWidth <= 768) {
      this.setState({ navClass: "darkheader", imglight: false });
      this.setState({ isStickyNav: false });
    } else {
      this.setState({ navClass: "", imglight: true });
      this.setState({ isStickyNav: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}

        <NavbarPage
          navclass={this.state.navClass}
          imglight={this.state.imglight}
          isStickyNav={this.state.isStickyNav}
          isMain={true}
        />

        {/* import hero */}
        <Hero />

        {/* import services */}
        <Services />

        {/* import Technologies */}
        <Technologies />

        <Clients />

        <Taas />

        {/* import ContactUs */}
        <ContactUs /> 

        {/* import Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default HomePage;
