import React, { Component } from "react";
import { NavLink } from "reactstrap";
import { Container, Row, Col } from "reactstrap";

class ContactUs extends Component {
  render() {
    return (
      <React.Fragment>
        {/* CTA START  */}
        <section className="section position-relative bg-gradient" id='contact'>
          <div className="bg-cta-overlay" />
          <Container className="container">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="text-center">
                  <h2 className="text-white mb-3">
                    Vamos trabalhar juntos?
                  </h2>
                  <NavLink href="mailto:contato@angoera.com.br" className="btn btn-primary mt-4">
                    Entre em contato conosco
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* CTA END  */}
      </React.Fragment>
    );
  }
}

export default ContactUs;
