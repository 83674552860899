import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class History extends Component {
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="service" className="section position-relative">
        <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="text-center">
                  <p className="w-80 mx-auto mb-2">
                    A Angoera Sistemas Eletrônicos foi fundada em 2008 por dois engenheiros da Escola Politécnica da USP. Desde então, a empresa vem colaborando com seus parceiros e clientes na criação de produtos inovadores, capazes de alavancar empresas e revolucionar mercados.
                  </p>
                  <p className="w-80 mx-auto mb-2">
                    A Angoera aplica em seus projetos as melhores práticas em desenvolvimento de
                    hardware e software. Porém, seu principal diferencial é sua vocação para a inovação.
                    Nossos projetos sempre contam com um insight diferenciado, uma vantagem
                    inesperada, algo que coloque o produto em um patamar acima do esperado pelo
                    cliente.
                  </p>
                  <p className="w-80 mx-auto mb-2">
                    Nossa equipe de P&D tem a capacidade de entender a real necessidade do usuário
                    final do produto e moldar soluções para atendê-la de forma única.
                    Conte conosco na jornada de realizar um produto de sucesso!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default History;
