import React, { Component } from "react";
import {
  Container,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";

//Import Stickey Header
import StickyHeader from "react-sticky-header";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Link } from 'react-scroll';

class NavbarPage extends Component {
  prevScrollpos = 0;

  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "service", navheading: "Serviços" },
        { id: 3, idnm: "technology", navheading: "Tecnologias" },
        { id: 4, idnm: "client", navheading: "Clientes" },
        { id: 4, idnm: "taas", navheading: "TaaS" },
        { id: 5, idnm: "contact", navheading: "Contato" },
      ],
      isOpen: false,
      topPos: "0",
      isOpenMenu: false,
      navCenterClass: "",
    };
    this.toggleLine = this.toggleLine.bind(this);

    this.handleScrollMenu = this.handleScrollMenu.bind(this);
  }

  handleScrollMenu = async () => {
    let currentScrollPos = window.pageYOffset;
    if (this.prevScrollpos > currentScrollPos) {
      await this.setState({ topPos: "0" });
    } else {
      await this.setState({ topPos: "-420px" });
    }
    this.prevScrollpos = currentScrollPos;

    if (window.innerWidth <= 768) {
      await this.setState({ navCenterClass: "" });
    } else {
      await this.setState({
        navCenterClass: "navbar-nav mx-auto navbar-center",
      });
    }
  };

  componentDidMount() {
    this.prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", this.handleScrollMenu);
    if (window.innerWidth <= 768) {
      this.setState({ navCenterClass: "" });
    } else {
      this.setState({ navCenterClass: "navbar-nav mx-auto navbar-center" });
    }
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  toggle = async () => {
    await this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    // let targetId = this.state.navItems.map((item) => {
    //   return item.idnm;
    // });
    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              className={`navbar navbar-expand-lg fixed-top navbar-custom ${
                this.props.isStickyNav === true
                  ? `sticky sticky-light`
                  : ` navbar-light`
              }`}
              id="navbar"
              style={{ top: this.state.topPos }}
            >
              <Container>
                <NavbarBrand href="/" className="logo">
                  <img
                    src={this.props.imglight ? logolight : logodark}
                    alt=""
                    className="logo-light"
                    height={40}
                  />
                </NavbarBrand>

                <NavbarToggler
                  className="navbar-toggler"
                  type="button"
                  aria-label="Toggle navigation"
                  onClick={this.toggle}
                >
                  <i className="remixicon-menu-fill" />
                </NavbarToggler>
                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >

                  <Nav
                    navbar
                    className="navbar-nav mx-auto navbar-center"
                    id="mySidenav"
                  >
                    {this.state.navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={
                          item.navheading === "Home"
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        {this.props.isMain && (
                          <Link to={item.idnm} className="nav-link" smooth={true}>
                            {" "}
                            {item.navheading}
                          </Link>
                        )}
                        {!this.props.isMain && (
                          <NavLink href={"/#" + item.idnm} className="nav-link">
                            {" "}
                            {item.navheading}
                          </NavLink>
                        )}

                      </NavItem>
                    ))}
                  </Nav>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink href="https://linkedin.com/company/angoera" className="nav-link" target="_blank">
                        <i className="remixicon-linkedin-line f-16" />
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
