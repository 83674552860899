import React, { Component } from "react";
import { Container } from "reactstrap";

//Import Section Title
import SectionTitle from "../../components/Common/SectionTitle";
import TechnologyBox from "./technology-box";

class Tecnologies extends Component {
  state = {
    technologies: [
      {
        title: "Comunicação sem fio",
        icon: "icon-wireless",
        description:
          "Elimine fios, cabos e conectores, adicionando comunicação sem fio ao seu produto.",
        link: "#",
      },
      {
        title: "Conectividade - IoT",
        icon: "icon-iot",
        description:
          "Conecte seu produto à Internet das Coisas para acessá-lo de qualquer lugar.",
        link: "#",
      },
      {
        title: "Geolocalização indoor e outdoor",
        icon: "icon-location",
        description:
          "Adicione essa tecnologia ao seu produto para saber exatamente onde ele está (ou esteve).",
        link: "#",
      },
      {
        title: "Baixo consumo",
        icon: "icon-batery",
        description:
          "Usando tecnologias de redução de consumo de energia, viabilizamos o funcionamento do produto com baterias por muito mais tempo.",
        link: "#",
      },
      {
        title: "Inteligência artificial",
        icon: "icon-ai",
        description:
          "Adicione algoritmos inteligentes e aprendizagem de máquina ao seu produto, proporcionando mais flexibilidade e desempenho.",
        link: "#",
      },
      {
        title: "Blockchain",
        icon: "icon-blockchain",
        description:
          "Incorpore a tecnologia Blockchain para garantir mais segurança, capacidade, disponibilidade e confiança na entrega dos dados.",
        link: "#",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="technology" className="section position-relative">
          <Container>
            <SectionTitle
              title="Tecnologias"
              description="Explore diferentes tecnologias e traga um valor único ao seu produto"
            />
            <TechnologyBox technologies={this.state.technologies} />
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default Tecnologies;
