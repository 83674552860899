import React, { Component } from "react";
import { Row } from "reactstrap";
import Fade from "react-reveal/Fade";

class AchievementBox extends Component {
  render() {
    return (
      <Fade cascade>
        <Row>
          {this.props.technologies.map((service, key) => (
            <div className="col-lg-4 col-md-6 flex" key={key}>
              <a href={service.link} target="_blank" rel="noopener noreferrer">
                <div className="technology-box mt-4">
                  <div className="p-3 achievement-logo-wrapper">
                    <img
                      src={service.img}
                      alt={service.title}
                      className={`img-fluid mx-auto d-block ${service.smaller ? 'achievement-logo-smaller' : ''}`}
                    />
                  </div>
                  <h5 className="text-dark font-weight-medium service-title mb-3 mt-5">
                    {service.title}
                  </h5>
                  <p className="text-muted mb-4 f-15">{service.description}</p>
                </div>
              </a>
            </div>
          ))}
        </Row>
      </Fade>
    );
  }
}

export default AchievementBox;
