import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Common/SectionTitle";

import InfiniteCarousel from 'react-leaf-carousel';


//Import Client images
import clientMarceloBaldino from "../../assets/images/client/test-marcelo-baldino.jpg";
import clientRodrigoGurdos from "../../assets/images/client/test-rodrigo-gurdos.jpg";
import clientRenatoFreitas from "../../assets/images/client/test-renato-freitas.jpg";
import clientMarceloVillar from "../../assets/images/client/test-marcelo-villar.jpg";

import clientTigre from "../../assets/images/clients-logo/client-tigre.png";
import clientGerdau from "../../assets/images/clients-logo/client-gerdau.png";
import clientSmartpills from "../../assets/images/clients-logo/client-smartpills.png";
import clientEnjoyit from "../../assets/images/clients-logo/client-enjoyit.png";
import clientCromai from "../../assets/images/clients-logo/client-cromai.png";
import clientInfoprice from "../../assets/images/clients-logo/client-infoprice.png";
import clientSeed from "../../assets/images/clients-logo/client-seed.png";
import clientTembici from "../../assets/images/clients-logo/client-tembici.png";
import clientWalmonof from "../../assets/images/clients-logo/client-walmonof.png";
import clientCefise from "../../assets/images/clients-logo/client-cefise.png";
import clientContemp from "../../assets/images/clients-logo/client-contemp.png";
import clientSaoMartinho from "../../assets/images/clients-logo/client-sao-martinho.png";
import clientFloe from "../../assets/images/clients-logo/client-floe.png";
import clientEdp from "../../assets/images/clients-logo/client-edp.png";
import clientYellow from "../../assets/images/clients-logo/client-yellow.png";

import Zoom from "react-reveal/Zoom";

class Clients extends Component {
  state = {
    clients: [
      {
        id: 1,
        img: clientMarceloBaldino,
        name: "Marcelo Baldino",
        technology: "Gerente nacional de marketing - Gerdau",
        description:
          "Para nós da Gerdau, a conexão com a Angoera viabilizou um antigo projeto: desenvolver um sistema de telemetria para estacas metálicas utilizadas nas fundações de obras na construção civil. A Angoera mostrou grande capacidade de inovação e disrupção e viabilizou o projeto superando todas as nossas expectativas, com grande geração de valor para nossos clientes e parceiros.",
      },
      {
        id: 2,
        img: clientMarceloVillar,
        name: "Mauricio Villar",
        technology: "Co-founder e COO - Tembici",
        description:
          "Quando iniciamos nosso sonho de revolucionar a mobilidade urbana, fazendo da bicicleta um meio de transporte real para as pessoas, a Angoera estava do nosso lado. O conhecimento técnico e a capacidade de desenvolvimento de produto de hardware da Angoera foram fundamentais para tirarmos do papel nosso primeiro protótipo e avançarmos com inovação durante os primeiros anos de desenvolvimento do que mais tarde se tornaria um dos maiores sistemas de bicicletas compartilhadas da América Latina.",
      },
      {
        id: 3,
        img: clientRenatoFreitas,
        name: "Renato Freitas",
        technology: "Founder e CTO - Yellow",
        description:
          "A qualidade do trabalho da Angoera foi determinante no sucesso da Yellow em levar os patinetes para as ruas. Com apoio deles, conseguimos adaptar o IoT das bicicletas e tivemos nosso primeiro patinete nas ruas em 10 dias, um recorde mundial. O nível de inovação e dedicação que eles colocaram no projeto foi surpreendente, e possibilitou inclusive que fizéssemos planos mais ousados, acelerando as conquistas que uma startup como a Yellow precisava.",
      },
      {
        id: 4,
        img: clientRodrigoGurdos,
        name: "Rodrigo Gurdos",
        technology: "Co-founder - SmartPills",
        description:
          "Firmamos uma parceria com a Angoera após nosso antigo fornecedor ter nos abandonado na metade do desenvolvimento do projeto de P&D. A equipe da Angoera foi muito perspicaz em aproveitar a documentação que já tínhamos e entregar os primeiros resultados em um prazo bem apertado, com um excelente custo benefício. Com isso demos continuidade no projeto com muita confiança no produto e, como consequência, fechamos com nosso primeiro grande cliente.",
      },
      // {
      //   id: 5,
      //   img: client1,
      //   name: "Venita Monroe",
      //   technology: "Graphic Designer, USA",
      //   description:
      //     "At vero eos site a accusamus that is iusto odio dignissimos ducimus a excepturi site as this cupiditate non blanditiis finish.",
      // },
      // {
      //   id: 6,
      //   img: client1,
      //   name: "Venita Monroe",
      //   technology: "Web Developer, USA",
      //   description:
      //     "At vero eos site a accusamus that is iusto odio dignissimos ducimus a excepturi site as this cupiditate non blanditiis finish.",
      // },
      // {
      //   id: 7,
      //   img: client1,
      //   name: "Leslie Cullison",
      //   technology: "PHP Development, USA",
      //   description:
      //     "At vero eos site a accusamus that is iusto odio dignissimos ducimus a excepturi site as this cupiditate non blanditiis finish.",
      // },
    ],
    clientsLogo: [
      { id: 1, img: clientTigre, name: "tigre", link: "https://www.tigre.com.br/" },
      { id: 2, img: clientGerdau, name: "gerdau", link: "https://www2.gerdau.com.br/" },
      { id: 3, img: clientSmartpills, name: "smartpills", link: "https://www.smartpills.com.br/" },
      { id: 4, img: clientEnjoyit, name: "enjoy", link: "https://letsenjoy.it/" },
      { id: 5, img: clientCromai, name: "cromai", link: "https://www.cromai.com/" },
      { id: 6, img: clientInfoprice, name: "infoprice", link: "https://www.infoprice.co/" },
      { id: 7, img: clientSeed, name: "seed", link: "https://www.seeddigital.com.br/" },
      { id: 8, img: clientTembici, name: "tembici", link: "https://tembici.com.br/" },
      { id: 9, img: clientWalmonof, name: "walmonof", link: "https://walmonof.com.br/" },
      { id: 10, img: clientCefise, name: "cefise", link: "http://www.cefise.com.br/" },
      { id: 11, img: clientContemp, name: "contemp", link: "https://contemp.com.br/" },
      { id: 12, img: clientSaoMartinho, name: "saomartinho", link: "https://www.saomartinho.com.br/" },
      { id: 13, img: clientFloe, name: "floe", link: "http://www.floecorp.com/", smaller: true },
      { id: 14, img: clientEdp, name: "edp", link: "https://www.edp.com.br/", smaller: true },
      { id: 15, img: clientYellow, name: "yellow", smaller: true },
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      960: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  render() {
    return (
      <React.Fragment>
        {/* <!-- TESTIMONIAL START --> */}

        <section className="section position-relative" id="client">
          <Container>
            <SectionTitle
              title="Clientes"
              description="Empresas que confiam na Angoera e já impactaram seus negócios."
            />

            
            <Row className="justify-content-center mb-5">
              <Col lg={12}>
                <Zoom cascade>
                  <div className="row justify-content-center">
                    {this.state.clientsLogo.map((clientsLogo, key) => (
                      <div className="col-sm-4 col-md-3 col-lg-2" key={key}>
                        <a href={clientsLogo.link} target="_blank" rel="noopener noreferrer">
                          <div className="p-3 clients-logo-wrapper">
                            <img
                              src={clientsLogo.img}
                              alt={clientsLogo.name}
                              className={`img-fluid mx-auto d-block clients-logo ${clientsLogo.smaller ? 'clients-logo-smaller' : ''}`}
                            />
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </Zoom>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <h3 className="text-center mt-5">Depoimentos</h3>
              </Col>
            </Row>
            <div className="overflow-hidden">
              <div className="negative-margin">
                <InfiniteCarousel
                  breakpoints={[
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                  ]}
                  dots={false}
                  showSides={true}
                  sidesOpacity={0}
                  sideSize={0.1}
                  slidesToScroll={1}
                  slidesToShow={3}
                  scrollOnDevice={false}
                >
                  {this.state.clients.map((clients, key) => (
                    <div className="item" key={key}>
                      <div className="testi-content-2 m-2">
                        
                        <div className="testi-img-content text-center">
                          <img
                            src={clients.img}
                            alt="client1"
                            className="img-fluid mx-auto d-block rounded-circle"
                          />
                          <h6 className="text-dark mt-3 mb-0">{clients.name}</h6>
                          <p className="text-muted f-14">{clients.technology}</p>
                        </div>
                        <div className="texti-2-box rounded p-4">
                          <div className="float-left mr-3">
                            <i className="remixicon-double-quotes-l testi-2-icon h3" />
                          </div>
                          <div className="position-relative overflow-hidden">
                            <p className="text-muted f-15 mb-0 white-space-normal">
                              {clients.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteCarousel>
              </div>
            </div>
          </Container>
        </section>
        {/* TESTIMONIAL END */}
      </React.Fragment>
    );
  }
}

export default Clients;
