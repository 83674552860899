import HomePage from "./pages/home";
import HistoryPage from "./pages/history";


const routes = [
  { path: "/historia", component: HistoryPage },
  { path: "/", component: HomePage},
];

export default routes;
