import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
//Import Components
import SectionTitle from "../../components/Common/SectionTitle";

//Import Images
import img1 from "../../assets/images/features-img-3.png";
import icoAgility from "../../assets/images/ico-agility.png";
import icoTimeToMarket from "../../assets/images/ico-time-to-market.png";
import icoPracticity from "../../assets/images/ico-practicity.png";
import icoPredictable from "../../assets/images/ico-predictable.png";
import icoIteractive from "../../assets/images/ico-iteractive.png";
import icoFlexibility from "../../assets/images/ico-flexibility.png";

import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

class Taas extends Component {
  render() {
    return (
      <React.Fragment>
        {/* FEATURES START  */}
        <section className="section bg-light position-relative pdass" id="taas">
          <Container>
            <SectionTitle
              title="Team as a Service"
              description="Que tal incorporar ao seu time uma equipe de especialistas em desenvolvimento de produtos eletrônicos?"
            />

            <Row className="align-items-center">
              <Col lg={12}></Col>
              <Col lg="4">
                <Zoom>
                  <h4 className=" font-weight-bold text-uppercase text-center">Seu time</h4>
                  <div className="features-img">
                    <img
                      src={img1}
                      alt="Fetures"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </Zoom>
              </Col>

              <div className="col-lg-7 offset-1">
                <div className="pr-lg-5 mt-4">
                  <p className="mb-4">
                    Com o nosso modelo de Team as a Service (TaaS) você pode incorporar ao seu time uma equipe de especialistas da Angoera, garantido as seguintes vantagens:
                  </p>
                  <Fade cascade bottom>
                  <div className="row">
                    
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoTimeToMarket} alt="Time to market" className="pdass-icon"/>
                      <div>
                        <h5>Time-to-market</h5>
                        <p className="text-muted">Time-to-market reduzido, contando com a experiência acumulada da Angoera.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoPracticity} alt="Praticidade" className="pdass-icon"/>
                      <div>
                        <h5>Praticidade</h5>
                        <p className="text-muted">Alocação dinâmica de competências conforme a etapa de projeto.</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoPredictable} alt="Previsibilidade" className="pdass-icon"/>
                      <div>
                        <h5>Previsibilidade</h5>
                        <p className="text-muted">Previsibilidade de budget mensal ao longo de todo o período de desenvolvimento.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoFlexibility} alt="Flexibilidade" className="pdass-icon"/>
                      <div>
                        <h5>Flexibilidade</h5>
                        <p className="text-muted">Flexibilidade de escopo do projeto, podendo aproveitar insights de inovação ao longo do desenvolvimento.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoAgility} alt="Agilidade" className="pdass-icon"/>
                      <div>
                        <h5>Agilidade</h5>
                        <p className="text-muted">Fluxo de projeto ágil (redefinição de atividades e priorização).</p>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-2 f-14 pdass-item">
                      <img src={icoIteractive} alt="Processo iterativo" className="pdass-icon"/>
                      <div>
                        <h5>Processo iterativo</h5>
                        <p className="text-muted">Entrega progressiva de valor: PoC, MVP, Produto Final, Incorporação gradual de melhorias.</p>
                      </div>
                    </div>
                  </div>
                  </Fade>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Taas;
