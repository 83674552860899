import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class ServiceBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: {}
    };
  }

  handleEnter = (key) => () => {
    const state = {hover: {}};
    state.hover[key] = true;
    this.setState(state);
  }

  handleLeave = (key) => () => {
    const state = {hover: {}};
    state.hover[key] = false;
    this.setState(state);
  }

  // onMouseEnter={} onMouseLeave={this.handleLeave(key)}
  render() {
    return (
      <React.Fragment>
        <Fade cascade bottom>
          <div className="row">
          {this.props.services.map((service, key) => (
            <div className="col-md-6 col-lg-3 flex" key={key}>
              <div className="service-box mt-4">
                <div>
                  <i className={`${service.icon} text-purple h1 service-icon`} />
                  <h5 className="text-dark font-weight-medium service-title f-18 mb-3 mt-5">
                    {service.title}
                  </h5>
                  {!this.state.hover[key] && <Fade>
                    <p className="text-muted mb-4 f-14">{service.description}</p>
                  </Fade>}
                  
                  {this.state.hover[key] && <Fade>
                    <ul className='p-2'>
                      {service.description2 && service.description2.split('\n').map(str => <li key={str} className="text-white mb-1 f-14">{str}</li>)}
                    </ul>
                  </Fade>}
                </div>
                <div className="text-center">
                  {!this.state.hover[key] && <button className="btn btn-primary" onClick={this.handleEnter(key)}>Saiba mais</button>}
                  {this.state.hover[key] && <button className="btn btn-primary" onClick={this.handleLeave(key)}>Voltar</button>}
                </div>
              </div>
            </div>
          ))}
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

export default ServiceBox;
