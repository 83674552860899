import React, { Component } from "react";
import { Row } from "reactstrap";
import Fade from "react-reveal/Fade";

class TechnologyBox extends Component {
  render() {
    return (
      <Fade cascade>
        <Row>
          {this.props.technologies.map((service, key) => (
            <div className="col-lg-4 col-md-6 flex" key={key}>
              <div className="technology-box mt-4">
                <i className={`${service.icon} text-navy-blue h1 technology-icon`} />
                <h5 className="text-dark font-weight-medium service-title mb-3 mt-5">
                  {service.title}
                </h5>
                <p className="text-muted mb-4 f-15">{service.description}</p>
              </div>
            </div>
          ))}
        </Row>
      </Fade>
    );
  }
}

export default TechnologyBox;
