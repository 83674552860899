import React, { Component } from "react";
import "./App.css";
import routes from "./routes";
import {
  withRouter,
  Route,
  Switch,
} from "react-router-dom";

//  Magnific-popup
import "./assets/css/magnific-popup.css";

//  css
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Route path="/">
        <Switch>
          {routes.map((route, idx) => (
            <Route path={route.path} component={route.component} key={idx} />
          ))}
        </Switch>
      </Route>

    );
  }
}

export default withRouter(App);
