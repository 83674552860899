import React, { Component } from "react";
import { Container } from "reactstrap";

//Import Section Title
import SectionTitle from "../../components/Common/SectionTitle";
import AchievementBox from "./achievement-box";

import achievementTop100 from "../../assets/images/achievement/achievement-top-100.png";
import achievementCietec from "../../assets/images/achievement/achievement-cietec.jpg";
import achievementDNAUsp from "../../assets/images/achievement/achievement-dna-usp.png";

class Achievements extends Component {
  state = {
    technologies: [
      {
        title: "Ranking 100 Open Startups",
        img: achievementTop100,
        description:
          "Top10 na categoria IoT do Ranking 100 Open Startups 2020",
        link: "https://www.openstartups.net/site/ranking/rankings-categories.html",
        smaller: true,
      },
      {
        title: "Cietec",
        img: achievementCietec,
        description:
          "Graduada no programa de incubação do CIETEC - USP",
        link: "https://www.cietec.org.br/empresas/empresas-graduadas/",
      },
      {
        title: "DNA USP",
        img: achievementDNAUsp,
        description:
          "Fundada por engenheiros politécnicos da USP",
        link: "http://www.inovacao.usp.br/dnausp/",
        smaller: true,
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="technology" className="section position-relative">
          <Container>
            <SectionTitle
              title="Selos e conquistas"
              description="A Angoera tem conquistado o reconhecimento de excelência de diversas entidades no mercado ao longo dos anos."
            />
            <AchievementBox technologies={this.state.technologies} />
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default Achievements;

